import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14')
];

export const server_loads = [0];

export const dictionary = {
		"/": [3],
		"/(app)/admin": [4,[2]],
		"/(app)/admin/page1": [5,[2]],
		"/(app)/admin/page2": [6,[2]],
		"/(app)/admin/sentry": [~7,[2]],
		"/auth": [12],
		"/auth/logout": [13],
		"/auth/next": [14],
		"/(app)/billing": [8,[2]],
		"/(app)/pricing": [~9,[2]],
		"/(app)/youtube": [10,[2]],
		"/(app)/youtube/[videoId]": [11,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';